import styled from 'styled-components';

import { devices } from '@/UI/Styles/Queries';

export const FloatingCart = styled.button<{ hasSelection: boolean }>`
  position: fixed;
  z-index: 2;
  top: 8px;
  right: 40px;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  transition: 200ms ease-in-out;
  background-color: ${({ theme, hasSelection }) =>
    hasSelection ? theme.brandTheme.brand100 : theme.migrateColors('background.btnTertiary', 'background')};
  border-radius: 50%;
  cursor: ${({ hasSelection }) => (hasSelection ? 'pointer' : 'default')};
  svg {
    position: absolute;
    top: 10px;
    left: 10px;
    path {
      fill: ${({ theme, hasSelection }) =>
        hasSelection
          ? theme.migrateColors('icon.inverse', 'white')
          : theme.migrateColors('background.brand', 'primary')};
    }
  }
  @media ${devices.XLarge} {
    right: 55px;
  }
`;

export const ServiceAmount = styled.div`
  position: absolute;
  top: 0;
  left: 32px;
  border-radius: 50%;
  min-width: 22px;
  min-height: 22px;
  width: 22px;
  height: 22px;
  background-color: ${({ theme }) => theme.migrateColors('background.notification', 'accent')};
  border: 2px solid ${({ theme }) => theme.migrateColors('border.soft', 'white')};
  p {
    text-align: center;
    font-size: 14px;
    color: ${({ theme }) => theme.migrateColors('text.btnPrimary', 'white')};
  }
`;

export const AlertModalContainer = styled.div`
  .cardAlert {
    .buttonsContainer {
      flex-wrap: wrap;
      row-gap: ${({ theme }) => theme.spacing[12]};
      button {
        width: 100%;
      }
      .cancelBtn {
        background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
        border: 1px solid ${({ theme }) => theme.migrateColors('border.secondary', 'gray50')} !important;
        color: ${({ theme }) => theme.brandTheme.brand100};
      }
    }
  }
  .confirmBtn {
    background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
    border: none;
    background-blend-mode: lighten;
    p {
      color: ${({ theme }) => theme.brandTheme.brand100};
      text-decoration: underline;
    }
    &:hover {
      background-blend-mode: lighten;
    }
  }
`;
