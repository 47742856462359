/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useTranslation } from 'react-i18next';
import { CalendarLine, Cart } from '@agendapro/emerald-icons';
import dynamic from 'next/dynamic';
import { ServicesOffered, GlobalContentLayout, CharlyBanner } from '@/components';
import useServicesState from '@/hooks/useServicesState';
import { useShowComponent } from '@/hooks/showComponent';
import usePageContext from '@/hooks/usePageContext';

import { ServiceModel, useServices } from '@/services/services';
import { useServiceProviders } from '@/services/serviceProviders';

import { Tab } from '@/UI/Atoms/Tab';
// import { Giftcard, useGiftcards } from '@/services/giftcards';
import { Giftcard } from '@/services/giftcards';
import { Paragraph, AlertModal } from '@/UI';
import * as St from './ServicesByLocation.styles';

const Giftcards = dynamic(() => import('@/components/Giftcards/Giftcards'));

const ActivationBar = dynamic(() => import('@/components/ActivationBar/ActivationBar'));

export const ServicesByLocationPage: FC = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const { locationId, token } = router.query as { locationId: string; token: string | null };
  const { data: providersByLocation } = useServiceProviders(locationId);
  const { data: servicesResponse, isLoading } = useServices(locationId, token);
  const { state, dispatch } = useServicesState(servicesResponse?.services);
  // const { data: giftcards, isLoading: isLoadingGiftcards } = useGiftcards(locationId);
  const [selectedOption, setSelectedOption] = useState({ label: t('TABS.SERVICES_TITLE'), value: '0' });
  const [selected, setSelected] = useState<{ services: Array<ServiceModel>; giftcards: Array<Giftcard> }>({
    services: [],
    giftcards: [],
  });
  const [alertModal, setAlertModal] = useState<{
    title: string;
    cancelButtonText: string;
    confirmButtonText: string;
    description: string;
    onConfirm: () => void;
    onCancel: () => void;
  } | null>(null);

  const { hide: hideServiceCart, show: showServiceCart, isShown: isServiceCartShown } = useShowComponent();
  const { hide: hideGiftcardCart, show: showGiftcardCart, isShown: isGiftcardCartShown } = useShowComponent();

  const [wiggle, setWiggle] = useState(false);
  const { isSalfa, companyOverview, setSelectedServices } = usePageContext();

  const tabData = [
    {
      label: t('TABS.SERVICES_TITLE'),
      value: '0',
    },
    {
      label: t('TABS.GIFTCARDS_TITLE'),
      value: '1',
    },
  ];

  const handleSelected = (name: string, items: ServiceModel[] | Giftcard[]) => {
    if (name === 'services' && selected.giftcards.length > 0) {
      setAlertModal({
        title: t('SERVICE_ALERT_TITLE'),
        cancelButtonText: t('SERVICE_CANCEL_TEXT'),
        confirmButtonText: t('SERVICE_CONFIRM_TEXT'),
        description: t('SERVICE_ALERT_TEXT'),
        onConfirm: () => {
          setSelected({ giftcards: [], services: items as ServiceModel[] });
          showServiceCart();
          setAlertModal(null);
        },
        onCancel: () => {
          hideServiceCart();
          setSelectedOption(tabData[1]);
          showGiftcardCart();
          setAlertModal(null);
        },
      });
      return;
    }
    if (name === 'giftcards' && selected.services.length > 0) {
      setAlertModal({
        title: t('GIFTCARD_ALERT_TITLE'),
        cancelButtonText: t('GIFTCARD_CANCEL_TEXT'),
        confirmButtonText: t('GIFTCARD_CONFIRM_TEXT'),
        description: t('GIFTCARD_ALERT_TEXT'),
        onConfirm: () => {
          setSelected({ giftcards: items as Giftcard[], services: [] });
          showGiftcardCart();
          setAlertModal(null);
        },
        onCancel: () => {
          setSelectedOption(tabData[0]);
          showServiceCart();
          setAlertModal(null);
        },
      });
      return;
    }
    setSelected((prev) => ({ ...prev, [name]: items }));
  };

  const giftcards = [];

  useEffect(() => {
    if (setSelectedServices) {
      setSelectedServices(!!selected.services.length);
    }
  }, [selected.services]);

  return (
    <GlobalContentLayout>
      {!companyOverview?.minisiteV2Active && <ActivationBar />}
      <CharlyBanner />

      {!isSalfa && !!selected.giftcards.length && (
        <St.FloatingCart
          className="click-gtm"
          id="button_giftcards_cart"
          data-testid="button_giftcards_cart"
          data-cy="button_giftcards_cart"
          hasSelection={!!selected.giftcards.length}
          disabled={!selected.giftcards.length}
          onClick={showGiftcardCart}
        >
          {!!selected.giftcards.length && (
            <St.ServiceAmount>
              <Paragraph>{selected.giftcards.length}</Paragraph>
            </St.ServiceAmount>
          )}
          <Cart size={30} className={wiggle ? 'wiggle' : ''} />
        </St.FloatingCart>
      )}
      {giftcards?.length ? (
        <>
          <Tab data={tabData} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
          {selectedOption.value === '0' ? (
            <ServicesOffered
              services={state}
              dispatch={dispatch}
              serviceProvider={providersByLocation?.length === 1 ? providersByLocation[0] : undefined}
              isLoading={isLoading}
              singleProvider={providersByLocation?.length === 1}
              selected={selected.services}
              setSelected={(items: ServiceModel[]) => handleSelected('services', items)}
              setWiggle={setWiggle}
              isCartShown={isServiceCartShown}
              hideCart={hideServiceCart}
              showCart={showServiceCart}
            />
          ) : (
            <Giftcards
              giftcards={giftcards || []}
              selected={selected.giftcards}
              setSelected={(items: Giftcard[]) => handleSelected('giftcards', items)}
              setWiggle={setWiggle}
              isCartShown={isGiftcardCartShown}
              hideCart={hideGiftcardCart}
              showCart={showGiftcardCart}
            />
          )}
        </>
      ) : (
        <ServicesOffered
          services={state}
          dispatch={dispatch}
          serviceProvider={providersByLocation?.length === 1 ? providersByLocation[0] : undefined}
          isLoading={isLoading}
          singleProvider={providersByLocation?.length === 1}
          selected={selected.services}
          setSelected={(items: ServiceModel[]) => handleSelected('services', items)}
          setWiggle={setWiggle}
          isCartShown={isServiceCartShown}
          hideCart={hideServiceCart}
          showCart={showServiceCart}
        />
      )}
      {alertModal && (
        <St.AlertModalContainer>
          <AlertModal type="warning" showCancelButton titleSize="sectionHeadline" {...alertModal} />
        </St.AlertModalContainer>
      )}
    </GlobalContentLayout>
  );
};
