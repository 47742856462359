import styled, { css } from 'styled-components';

export const TabWrapper = styled.div<{ selected: number; noBackground?: boolean }>`
  ${({ theme, selected, noBackground }) => css`
    > div {
      margin: 0;
      column-gap: 4px;
    }
    border-bottom: ${noBackground ? 'none' : `3px solid ${theme.brandTheme.brand100}`};
    margin-bottom: 44px;
    div > button {
      width: 50%;
      justify-content: center;
      box-shadow: ${noBackground ? '6.89757px -3.19116px 17.0625px rgba(0, 0, 0, 0.045)' : 'none'};
    }
    div > button:nth-of-type(${selected + 1}) {
      background: ${noBackground ? theme.palette.white : theme.brandTheme.brand100};
      border-top-right-radius: ${theme.borderRadius.md};
      border-top-left-radius: ${theme.borderRadius.md};
      & > p {
        color: ${noBackground ? theme.palette.grays100 : theme.palette.white} !important;
      }
      & > div {
        display: none;
      }
    }
  `}
`;
