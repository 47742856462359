import { FC } from 'react';
import { dehydrate, QueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { GetServerSideProps } from 'next';

import { PageProvider } from '@/context/PageContext';
import { ServicesByLocationPage } from './components/ServicesByLocation';
import { BranchOfficeHeader } from './components/BranchOfficeHeader';
import { BranchOfficeProps } from './BranchOffice.types';
import { getCompanyOverview } from '@/services/company/company.repo';
import { useLocations } from '@/services/locations';
import { useIframeRouter } from '@/hooks/useIframeRouter';

const BranchOffice: FC<BranchOfficeProps> = (props) => {
  const { t } = useTranslation();
  const { countryCode, locationId, companyOverview, companySlug } = props;
  const { routerPush } = useIframeRouter();

  useLocations({
    companyId: companyOverview?.id,
    onSuccess: (data) => {
      const locationToLoad = data.find((location) => location.id === +locationId);

      if (!locationToLoad || !locationToLoad.validLocation) {
        routerPush(`/${countryCode}`);
      }
    },
  });
  return (
    <PageProvider
      companySlug={companySlug}
      locationId={locationId}
      companyOverview={companyOverview}
      nextSeo={{
        title: `${companyOverview?.name} | ${t('ONLINE_BOOKING')}`,
        description: `${t('SEO.LOCATIONS_DESCRIPTION_FIRST')} ${companyOverview?.name} ${t(
          'SEO.LOCATIONS_DESCRIPTION_SECOND',
        )}`,
      }}
    >
      <BranchOfficeHeader />
      <ServicesByLocationPage />
    </PageProvider>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const companySlug = ctx.req.headers.host?.split('.')[0];
  const { countryCode, locationId } = ctx.params as { countryCode: string; locationId: string };
  const queryClient = new QueryClient();

  try {
    const companyOverview = await queryClient.fetchQuery(
      ['getCompanyOverview', companySlug, countryCode, locationId],
      ({ queryKey }) => getCompanyOverview(queryKey[1], queryKey[2], queryKey[3]),
    );

    const props = {
      companyOverview,
      countryCode,
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
      locationId,
      companySlug,
    };

    return {
      props,
    };
  } catch (e) {
    return {
      redirect: {
        permanent: false,
        destination: 'https://agendapro.com/',
      },
    };
  }
};

export default BranchOffice;
