import { FC } from 'react';
import { EmeraldThemeProvider, TabContainer, Tab as EmeraldTab } from '@agendapro/emerald/v2';

import { TabProps } from './types';
import * as st from './Tab.styles';

export const Tab: FC<TabProps> = ({ data, selectedOption, setSelectedOption, noBackground, className }) => (
  <EmeraldThemeProvider>
    <st.TabWrapper
      selected={data.findIndex((item) => item.value === selectedOption.value)}
      noBackground={noBackground}
      className={className}
      data-cy="tab_wrapper"
    >
      <TabContainer>
        {data?.map((item) => (
          <EmeraldTab
            key={item.value}
            data={{ value: item.value, label: item.label }}
            onClick={setSelectedOption}
            selectedOption={selectedOption}
            leftAdornment={item.leftAdorment}
          />
        ))}
      </TabContainer>
    </st.TabWrapper>
  </EmeraldThemeProvider>
);
